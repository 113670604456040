.recipeContainer {
    width: 100%;
    max-height: 50vh;
    overflow-y: scroll;
}

.recipeImg {
    width: 400px;
    height: auto;
    position: relative;
}

.recipeHead {
    position: absolute;
    max-width: 340px;
    top: 4rem;
    left: 4rem;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.8rem;
    margin: 10px;
    z-index: 1;
    box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.2);
}

.title {
    // font-family: 'Lobster', cursive;
    margin: 0;
    font-weight: 700;
    font-size: 1.1rem;
    color: #3e3e3e;
    font-style: italic;
}

.cooktime {
    display: block;
    font-size: .8rem;
    color: #6e6e6e;
    font-weight: 700;
    margin: 10px;
    margin-top: 4px;

}

.description {
    font-size: 0.8rem;
    font-weight: 700;
    color: #3e3e3e;
    padding: 0 20px;
    font-style: italic;
}

.instructionsList {
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;

    li {
        padding: 0 10px;
        font-size: 0.8rem;
        font-weight: 700;
        color: #3e3e3e;
    }
}

.cookStep {
    text-align: center;
    padding: 8px;
    display: inline-block;
    background-color: #d9e7ff50;
    border: 1px solid #d9e7ff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-family: 'Lobster', cursive;
    font-size: .8rem;
    margin-right: 10px;
    vertical-align: middle;
}

.cookStepContent {
    font-size: 1rem;
    display: inline-block;
    width: calc(100% - 100px);
    vertical-align: middle;
}

.divider {
    height: 1px;
    width: 80%;
    background-color: #e3e3e3;
    margin: 10px auto;
}