@import '../../style.scss';

.navContainer {
    display: flex;
    position: fixed;
    bottom: 100px;
    padding: 10px;
    left: 50%;
    transform-origin: center;
    width: 100%;
    max-width: 480px;

    @media screen and (max-width:640px) {
        max-width: 280px;
        left: 50%;
        box-shadow: rgb(0, 0, 0) 0px 0px 100px 160px !important;
    }
}

.searchForm {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.searchInput {
    padding: 10px 20px;
    width: 100%;
    border: none;
    background-color: #e6e6e6;
    border-radius: 50px;

    &::placeholder {
        color: #999;
        font-size: 1rem;
        font-weight: 500;
        font-style: italic;
    }
}

.searchBtn {
    cursor: pointer;
    padding: 10px;
    border: none;
    background: linear-gradient(45deg, $wineColor 20%, $cherryColor 100%);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    border-radius: 50px;
    margin-left: 10px;
    width: 50px;

    img {
        width: 20px;
        height: 20px;
    }
}

.createRecipeContainer {
    display: flex;
    border-right: 1px solid #e6e6e6;
    margin: 0 10px;
}

.createRecipeBtn {
    margin-left: 0;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 50px;
    padding: 8px;
    border: none;
    transition: all .2s ease-in-out;
    cursor: pointer;

    img {
        transition: all .2s ease-in-out;
        vertical-align: middle;
        filter: drop-shadow(2px 2px 0 white);
        height: 100%;
    }

    &:hover img.pink {
        filter: drop-shadow(2px 2px 0 white) drop-shadow(-2px 2px 0 white) drop-shadow(2px -2px 0 white) drop-shadow(-2px -2px 0 white) drop-shadow(0 6mm 4mm $cherryColor);
    }

    &:hover img.green {
        filter: drop-shadow(2px 2px 0 white) drop-shadow(-2px 2px 0 white) drop-shadow(2px -2px 0 white) drop-shadow(-2px -2px 0 white) drop-shadow(0 6mm 4mm $oliveColor);
    }
}