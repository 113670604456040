.galleryGrid {
    max-width: 100%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 1fr;
    grid-auto-flow: row dense;
    grid-gap: 10px;
    counter-reset: boxes;
    background-color: #000;

    @media screen and (max-width:640px) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 0 !important;
        gap: 0 !important;
    }
}