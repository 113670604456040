@import '../../style.scss';

.container {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0,0,0,.5);
}

.logo {
    font-family: 'Lobster', cursive;
    color: #434343;
    background: linear-gradient(45deg, $wineColor, $cherryColor);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 10px;
    font-size: 1.6rem;
}